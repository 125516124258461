<template>
	<div>
		<common-table
				:itemScope="['status']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				ref="ct"
				:leftConfig="leftConfig"
				:btnActionEvent="btnActionEvent"
				pageTitle="预约记录"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:status="{ item }">
				<template v-if="item.status === '已跟进'">
					<span style="color: #11B211">已跟进</span>
				</template>
				<template v-else>
					<span style="color: #F25757">{{ item.status }}</span>
				</template>
			</template>
		</common-table>
		<template v-if="dialog">
			<form-menu
					:center="true"
					:center_btn="true"
					:listDeploy="listDeploy"
					height="50%"
					@validateSave="validateSave"
					rightbtn_text="提交"
					:ridDivision="true"
					:dialog.sync="dialog"
					title="预约详情"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/appointmentRecord.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import detailsDining from '../common-order-js/details-dining.js';
import detailsIntoPark from '../common-order-js/details-intoPark.js';
import detailsMeeting from '../common-order-js/details-meeting.js';

export default {
	// 预约记录
	components: {formMenu},
	mixins: [commonCurd, detailsDining, detailsIntoPark, detailsMeeting],
	data() {
		return {
			api,
			dialog: false,
			listDeploy: {},
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '预约详情'
				}
			],
			headers: [
				// {text: '预约产品', value: 'description'},
				{text: '预约人姓名', value: 'bookername'},
				{text: '预约人电话', value: 'telephone'},
				{text: '预约类型', value: 'ptypedesc'},
				{text: '状态', value: 'status'},
				{text: '跟进人', value: 'handler'},
				{text: '申请时间', value: 'createtime'},
				{text: '跟进时间', value: 'handletime'},
				{text: '操作', value: 'actions', width: '80px'}
			],
			leftConfig: [
				{type: 'input', label: '输入预约产品', property: 'seacherkey'},
				{type: 'input', label: '输入预约人电话', property: 'tel'},
				{type: 'select', label: '预约类型', property: 'ptype', items: []},
				{type: 'singleTime', label: '申请时间', property: 'createtime'},
				{type: 'select', label: '状态', property: 'status', items: []}
			]
		}
	},
	methods: {
		async otherTableOperate(e, item) {
			this.dialog = true;
			let deploy = {};
			let field = '';
			switch (item.ptype) {
				case 'M':
					deploy = JSON.parse(JSON.stringify(this.meetingDeploy));
					field = 'meetingRs';
					break;
				case '0':
					deploy = JSON.parse(JSON.stringify(this.intoParkDeploy));
					break;
				case '1':
					deploy = JSON.parse(JSON.stringify(this.diningDeploy));
					break;
				default:
					break;
			}
			if (!this.globalMethods.isEmpty(deploy)) {
				this.axios.post(this.api.load, {sqlid: item.sqlid, ptype: item.ptype}).then((res) => {
					if (res.code === this.staticVal.Code.Success) {
						let data = res.data[field];
						deploy.row.forEach((obj) => {
							delete obj.default;
							if (obj.connector?.props) {
								delete obj.connector.props.value;
							}
							if (data[obj.property]) {
								obj.default = data[obj.property];
							}
						});
						if (!data?.handler) {
							// 没有跟进人，则默认显示当前登录用户和当前时间
							// deploy.row[10].default = sessionStorage.getItem('userid');
							// deploy.row[11].default = this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
						}
						this.listDeploy = deploy;
						this.listDeploy.sqlid = item.sqlid;
						this.listDeploy.ptype = item.ptype;
						this.dialog = true;
					}
				});
			}
		},
		validateSave(item, callback) {
			if (item.done) {
				item.form.sqlid = this.listDeploy.sqlid;
				item.form.ptype = this.listDeploy.ptype;
				this.axios.post(this.api.confirm, item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("跟进预约成功");
						callback();
						this.list();
					} else {
						this.$refs.ct.$refs.formRef.load = false;
					}
				});
			}
		}
	},
	mounted() {
		this.axios.post(this.select_data, {keyname: 'appointtype,mstatus'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.leftConfig[2].items = res.data[0].values;
				this.leftConfig[4].items = res.data[1].values;
				this.meetingDeploy.row[8].connector.props.items = res.data[1].values
			}
		});
	}
}
</script>

<style scoped lang="scss">

</style>
