export default {
	data() {
		return {
			meetingDeploy: {
				row: [
					// {
					// 	subassembly: 'VTextField',
					// 	cols: 4,
					// 	property: 'description',
					// 	connector: {
					// 		props: {
					// 			label: "预约产品",
					// 			readonly: true
					// 		}
					// 	}
					// },
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'bookername',
						connector: {
							props: {
								label: "预约人姓名",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'telephone',
						connector: {
							props: {
								label: "预约人电话",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'mtype',
						connector: {
							props: {
								label: "预约类型",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'startdate',
						connector: {
							props: {
								label: "开始时间",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'enddate',
						connector: {
							props: {
								label: "结束时间",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'budget',
						connector: {
							props: {
								label: "预算",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'persons',
						connector: {
							props: {
								label: "人数",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'createtime',
						connector: {
							props: {
								label: "申请时间",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: 'WAutocomplete',
						property: 'status',
						connector: {
							props: {
								label: "跟进状态",
								items: []
							},
							class: 'edit-text'
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: 'memo',
						connector: {
							props: {
								label: "预约需求",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'handler',
						connector: {
							props: {
								label: "跟进人",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: 'handletime',
						connector: {
							props: {
								label: "跟进时间",
								readonly: true
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: 'handleinfo',
						connector: {
							props: {
								label: "跟进记录",
								required: true
							}
						}
					}
				]
			}
		}
	}
}
