export default {
	data() {
		return {
			diningDeploy: {
				row: [
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约产品"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约人姓名"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约人电话"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约类型"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "用餐日期"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "用餐时段"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "餐位"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "人数"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "申请时间"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: '',
						connector: {
							props: {
								label: "预约需求"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "跟进人"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "跟进时间"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: '',
						connector: {
							props: {
								label: "跟进记录"
							}
						}
					}
				]
			}
		}
	}
}
