export default {
	data() {
		return {
			intoParkDeploy: {
				row: [
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约产品"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约人姓名"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约人电话"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "身份证号"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "预约类型"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "入园日期"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: '',
						connector: {
							props: {
								label: "预约备注"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "跟进人"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 4,
						property: '',
						connector: {
							props: {
								label: "跟进时间"
							}
						}
					},
					{
						subassembly: 'VTextField',
						cols: 12,
						property: '',
						connector: {
							props: {
								label: "跟进记录"
							}
						}
					}
				]
			}
		}
	}
}
